@font-face {
    font-family: 'Metric';
    font-style: normal;
    font-weight: normal;
    src: local('Metric'), url('../fonts/Metric.woff') format('woff');
  }
  
  
  @font-face {
    font-family: 'MetricBold';
    font-style: normal;
    font-weight: normal;
    src: local('MetricBold'), url('../fonts/MetricBold.woff') format('woff');
  }
  